<template>
    <div class="page-content">
        <div class="grid">
            <div class="row no-gutters page-list-header">
                <div class="col l-6 m-6 c-12">
                    <h4 class="page-list-title"><md-icon>note_add</md-icon> <span>{{title}}</span></h4>
                </div>
                <div class="col l-6 m-6 c-12">
                    <div class="page-list-action">
                        <md-button v-shortkey="['ctrl', 'l']" @shortkey="submit()" @click="submit()" class="md-raised md-accent"><span>L</span>ưu<md-tooltip>Lưu (Ctrl + L)</md-tooltip></md-button>
                        <md-button v-shortkey="['ctrl', 'i']" @shortkey="back()" @click="back()" class="md-raised">Quay lạ<span>i</span><md-tooltip>Quay lại (Ctrl + Q)</md-tooltip></md-button>
                    </div>
                </div>
            </div>
            <div class="page-list-body">
                <div class="form-body">
                    <div class="row">
                        <div class="col l-6 m-6 c-12">
                            <md-field :class="{'md-invalid': submitted && $v.entity.code.$error }">
                                <label for="code">Mã tiền tệ</label>
                                <md-input name="code" v-model="entity.currencyCode"></md-input>
                                <span class="md-error" v-if="submitted && !$v.entity.code.required">Vui lòng nhập mã tiền tệ</span>
                            </md-field>

                            <md-field :class="{'md-invalid': submitted && $v.entity.currencyName.$error }">
                                <label for="currencyName">Tên tiền tệ</label>
                                <md-input name="currencyName" v-model="entity.currencyName"></md-input>
                                <span class="md-error" v-if="submitted && !$v.entity.currencyName.required">Vui lòng nhập tên tiền tệ</span>
                            </md-field>

                            <md-field :class="{'md-invalid': submitted && $v.entity.rate.$error }">
                                <label for="rate">Tỉ giá/vnđ</label>
                                <md-input name="rate" v-model="entity.rate"></md-input>
                                <span class="md-error" v-if="submitted && !$v.entity.rate.required">Vui lòng nhập tỉ giá tiền tệ</span>
                            </md-field>

                            <div class="radio-group">
                                <label>Trạng thái</label>
                                <div class="form-control ">
                                    <md-radio v-model="entity.isActive" :value="true">Kích hoạt</md-radio>
                                    <md-radio v-model="entity.isActive" :value="false">Khóa</md-radio>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
   </div>
</template>
<script>
    import currencyService from '../../../api/currencyService';
    import messageBox from '../../../utils/messageBox';
    import { required } from 'vuelidate/lib/validators';
    import { mapActions } from 'vuex';

    export default ({
        metaInfo: {
            title: 'Thêm/Cập nhật tiền tệ'
        },
        data() {
            return {
               title: '',
               id: 0,
               submitted: false,
               entity: { id: 0, currencyCode: '', currencyName: '', rate: 1, isActive: true },
            }
        },
        created(){
            this.id = this.$route.params.id;
            if(this.id > 0){
               this.title = 'Cập nhật tiền tệ';
               this.getById();
            }
            else{
               this.title = 'Thêm mới tiền tệ';
            }
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            submit(){
               this.submitted = true;
               this.$v.$touch();
               if (this.$v.$invalid) {
                   return;
               }
               if(this.id > 0){
                   this.edit();
               }
               else{
                   this.add();
               }
            },

            add(){
               this.setLoading(true);
               currencyService.add(this.entity).then((response) => {
                   if(response.statusCode == 200){
                       messageBox.showMessage("Thêm mới thành công");
                   }
                   else{
                       messageBox.showWarning(response.data);
                   }
                }).finally(() => { this.setLoading(false); });
            },

            edit(){
                this.setLoading(true);
                currencyService.edit(this.entity).then((response) => {
                   if(response.statusCode == 200){
                       messageBox.showMessage("Cập nhật thành công");
                        this.$router.push('/currency');
                   }
                   else{
                       messageBox.showWarning(response.data);
                   }
                }).finally(() => { this.setLoading(false); });
            },

            getById(){
                this.setLoading(true);
                currencyService.getById(this.id).then((response) => {
                   if(response.statusCode == 200){
                       this.entity = response.data;
                   }
                   else{
                       messageBox.showWarning(response.data);
                   }
                }).finally(() => { this.setLoading(false); });
            },

            back(){
                this.$router.push('/currency');
            },
        },
        validations: {
            entity: {
                currencyCode: { required },
                currencyName: { required },
                rate: { required }
            }
        },
    })

</script>
